<template>
  <component-frame title="Tabs">
    <div class="sg-page__heading"></div>

    <h3 id="tabs">Tabs</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                href="#home"
                aria-controls="home"
                role="tab"
                data-toggle="tab"
              >
                01 - Mortgate Release
              </a>
            </li>
            <li class="nav-item position-static">
              <a
                href="#"
                class="nav-link dropdown-toggle"
                id="myTabDrop1"
                data-toggle="dropdown"
                aria-controls="myTabDrop1-contents"
                aria-expanded="false"
              >
                Applications
                <span class="caret"></span>
                <span class="badge badge-success">1</span>
              </a>
              <div
                class="dropdown-menu dropdown-menu-grid py-0"
                aria-labelledby="dropdownMenuButton"
              >
                <div class="container">
                  <div class="row">
                    <div class="col border border-dark pt-4 pb-1">
                      <span class="tab-status badge-success">Signed</span>
                      <a href="#">02 - DG bylaw road closure</a>
                    </div>
                    <div class="col border border-dark pt-4 pb-1">
                      <a href="#">03 - Mortgage caso</a>
                    </div>
                    <div class="col border border-dark pt-4 pb-1">
                      <a href="#">04 - transfer caso</a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <div class="tab-content">
            Tab content
          </div>
        </div>
        <div class="sg-component__code">
          <div
            class="sg-component__toggle js-toggle-code"
            data-text-swap="Hide Code"
            v-toggleCode
          >
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;ul class="nav nav-tabs" role="tablist"&gt;
  &lt;li class="active"&gt;&lt;a href="#home" aria-controls="home" role="tab" data-toggle="tab"&gt;Home&lt;/a&gt;&lt;/li&gt;
  &lt;li&gt;&lt;a href="#profile" aria-controls="profile" role="tab" data-toggle="tab"&gt;Profile&lt;/a&gt;&lt;/li&gt;
  &lt;li&gt;&lt;a href="#messages" aria-controls="messages" role="tab" data-toggle="tab"&gt;Messages&lt;/a&gt;&lt;/li&gt;
  &lt;li class="dropdown"&gt;
    &lt;a href="#" class="dropdown-toggle" id="myTabDrop1" data-toggle="dropdown" aria-controls="myTabDrop1-contents" aria-expanded="false"&gt;Dropdown &lt;span class="caret"&gt;&lt;/span&gt;&lt;/a&gt;
    &lt;ul class="dropdown-menu" aria-labelledby="myTabDrop1" id="myTabDrop1-contents"&gt;
      &lt;li&gt;&lt;a href="#dropdown1" role="tab" id="dropdown1-tab" data-toggle="tab" aria-controls="dropdown1"&gt;Dropdown 1&lt;/a&gt;&lt;/li&gt;
      &lt;li&gt;&lt;a href="#dropdown2" role="tab" id="dropdown2-tab" data-toggle="tab" aria-controls="dropdown2"&gt;Dropdown 2&lt;/a&gt;&lt;/li&gt;
    &lt;/ul&gt;
  &lt;/li&gt;
&lt;/ul&gt;
&lt;div class="tab-content"&gt;
  &lt;div role="tabpanel" class="tab-pane active" id="home"&gt;First tab&lt;/div&gt;
  &lt;div role="tabpanel" class="tab-pane" id="profile"&gt;Second tab&lt;/div&gt;
  &lt;div role="tabpanel" class="tab-pane" id="messages"&gt;Third tab&lt;/div&gt;
  &lt;div role="tabpanel" class="tab-pane" id="settings"&gt;Fourth tab&lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li></li>
        </ul>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "tabs",
  data() {
    return {
      title: "Tabs",
      menu: false
    };
  }
};
</script>
